<template>
    <div class="modal fade loginModalToggleform" id="registerModalToggle" aria-hidden="true" aria-labelledby="registerModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="e-p-header-bc1">
                    <div class="e-p-sections-bc1" v-if="siteSettings">
                        <a data-bs-dismiss="modal" >
                            <img  :src="siteSettings.aws_url + siteSettings.storage_path?.domain_image + siteSettings.logo" alt="logo" class="hdr-logo-bc-1">
                        </a>
                    </div>
                    <div class="e-p-sections-bc1">
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <button title="Register" class="e-p-section-item-bc2" data-bs-toggle="modal" href="#loginModalToggle">
                                <span>{{translatedLangData('log-in','Log in')}}</span>
                            </button>
                            <div style="margin-left: 1rem; margin-right: 0.2rem;">
                                <button @click="refreshData()" type="button" class="btn-close" data-bs-dismiss="modal" 
                                    aria-label="Close"><span class="fa fa-close"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="e-p-content-bc1">
                    <div class="e-p-body-bc" style="overflow-y: auto;">
                        <div class="login-page-abc">
                            <div class="login-page">
                                <div class="login-box">
                                    <div class="login-card">
                                        <div class="login-card-header">
                                            <span>{{translatedLangData('new-member','New Member?')}}</span>
                                            <div class="login-text">
                                                <p>{{translatedLangData('register-now','Register Now,')}}</p>
                                                <img  src="@/assets/images/hand-logo.webp" alt="waving-hand" style="height: 24px; width: 24px;">
                                            </div>
                                        </div>
                                        <div class="form_wrapper">
                                            <div class="form_wrap">
                                                <form style="width: 100%;" class="animateSignInFormMobileNo form_fild phone_form">
                                                    <div class="mobile-input">

                                                        <span>{{translatedLangData('mobile-number','Mobile Number')}}*</span>
                                                        <div id="sign-in-Input-Box" class="input-box">

                                                            <!-- <select v-model="form.code" class="form-select textfirst-con "
                                                                aria-label="Default select example">
                                                                <option :value="allowedCountry.phonecode"
                                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                    :disabled="allowedCountry.phonecode !== '91'"
                                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                            </select> -->
                                                            <div class="country-code-flag-top-wrapper" ref="dropdownWrapper">
                                                                <div class="country-code-flag-top-sec" @click="clickCountry = !clickCountry" :class="timerRunning?'pointer-none':''">
                                                                    <span class="drp-btn">+{{form?.code}}</span>
                                                                    <img :src="selectedCountryflag" alt="" class="india-flag"> 
                                                                </div>
                                                                <ul class="country-code-flag-sec show-country-code" v-if="clickCountry">
                                                                    <li @click="selectCountry(item)" v-for="(item,index) in siteSettings?.country_code_list" :key="index">
                                                                        <span class="drp-btn">+ {{item?.phonecode}}</span>
                                                                        <img :src="item?.flag" alt="" class="india-flag"> 
                                                                    </li>
                                                                </ul>
                                                            </div>  
                                                            <!-- <span class="drp-btn">+91</span>
                                                            <img  src="@/assets/images/ind-flag-icon.webp" alt="" class="india-flag"> -->
                                                            <div class="str-line">
                                                                <img  src="@/assets/images/straight-line1.webp" alt="">
                                                            </div>
                                                            <input :disabled="timerRunning" id="login-mobile-number" type="tel" v-model="form.mobileNo" minlength="10" @input="checkMobileNoLength" maxlength="10" :placeholder="translatedLangData('enter-mobile-number','Enter Your Mobile Number')" formcontrolname="mobileNo">
                                                            <img  src="@/assets/images/close.svg" width="25px" alt="" v-if="!phnValid && initialPhnIcon" class="check-vrf-img">
                                                            <img  src="@/assets/images/right-click-check.svg" alt="" v-if="phnValid && initialPhnIcon" class="check-vrf-img">
                                                        </div>
                                                        <div class="justify-content-start set-err-text"
                                                            v-if="showErrorMobile && form.mobileNo == ''">
                                                            <p class="wrong-color">{{translatedLangData('please-enter-phone-number','Please enter mobile number.')}}</p>
                                                        </div>
                                                        <div class = "justify-content-start set-err-text" v-if="showErrorNumberLength">
                                                            <p class="wrong-color">{{translatedLangData('invalid-number-add-correct','Invalid number. Please add correct number.')}}</p>
                                                        </div>
                                                        <div class="terms">
                                                            <div class="radio-check">
                                                                <img v-if="isChecked" @click="isChecked= !isChecked" src="@/assets/images/checked.webp" alt="" style="height: 1rem;" class="radio-checked">
                                                                <img v-if="!isChecked" @click="isChecked= !isChecked" src="@/assets/images/unchecked.webp" alt="" style="height: 1rem;" class="radio-checked">
                                                            </div>
                                                            <span @click="isChecked= !isChecked">{{translatedLangData('confirmation-18-plus','I confirm that I am 18 years old or above and agree to the terms and conditions.')}}</span>
                                                        </div>

                                                        <div class="forgot-btn-box">
                                                            <span v-if="timerRunning" style="margin-right: 10px;">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                                                            <button type="button" class="get-otp-btn" :disabled="loadingOtp || timerRunning || !phnValid || !isChecked" @click="sendOtpCall()"
                                                                :class="{ 'btn-loading': loadingOtp }"><span class="text-white">{{ resendOtp ? translatedLangData('resend-otp','Resend OTP') : translatedLangData('get-otp','Get OTP') }}</span>
                                                            </button>
                                                        </div>

                                                        <div class="otp-sec mb-3" v-if="isOtpApiCalled">
                                                            <div class="number-header "><span>{{translatedLangData('enter-otp','Enter OTP')}}*</span>
                                                                <div class="otp-box-sec">
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[0]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 0)" ref="otp-box-0" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[1]" class="form-control" type="tel" maxlength="1"
                                                                            ref="otp-box-1" @keydown="handleOtpBoxKeyDown($event, 1)" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[2]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 2)" ref="otp-box-2" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[3]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 3)" ref="otp-box-3" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[4]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 4)" ref="otp-box-4" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[5]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 5)" ref="otp-box-5" placeholder="_">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="justify-content-start set-err-text"
                                                                v-if="showValidationError && getOtp().length < 6">
                                                                <p class="wrong-color">Pleas Enter OTP.</p>
                                                            </div>
                                                        </div>

                                                        <div class="password-input"> <span>{{translatedLangData('user-id','User ID')}}*</span>
                                                            <div class="changepassword-box">
                                                                <input class="pass_log_id" v-model="form.userName" type="text" :placeholder="translatedLangData('enter-user-id','Enter User ID')"
                                                                @input="handleInputUserName($event)" minlength="6" maxlength="25"
                                                                :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }">
                                                            </div>
                                                            <div class="justify-content-start set-err-text"
                                                                v-if="showValidationError && form.userName == ''">
                                                                <p class="wrong-color">{{translatedLangData('please-enter-user-id','Please Enter User ID.')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="userIdValidation">
                                                                <img class="succ-wrong-img" v-if="!userIdValidation1" src="@/assets/images/request-right-icon.webp" alt="">
                                                                <img class="succ-wrong-img" v-if="userIdValidation1" src="@/assets/images/wrong-icon.webp" alt="">
                                                                <p :class="userIdValidation1 ? 'wrong-color':'success-color'">{{translatedLangData('must-be-between-6-25-characters','Must Be Between 6-25 Characters!')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="userIdValidation">
                                                                <img class="succ-wrong-img" v-if="!userIdValidation2" src="@/assets/images/request-right-icon.webp" alt="">
                                                                <img class="succ-wrong-img" v-if="userIdValidation2" src="@/assets/images/wrong-icon.webp" alt="">
                                                                <p :class="userIdValidation2 ? 'wrong-color':'success-color'">{{translatedLangData('must-be-alphanumeric','Must be Alphanumeric!')}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="password-input"> <span>{{translatedLangData('password','Password')}}* </span>
                                                            <div class="changepassword-box">
                                                                <input  class="pass_log_id" :type="passwordFieldType" maxlength="20" minLength="8"
                                                                    @input="validatePassword" v-model="form.password" 
                                                                    :placeholder="translatedLangData('enter-password','Enter Your Password')"
                                                                    :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }">
                                                                <span @click="toggleShow('password')" class="fa fa-fw field_icon toggle-password">
                                                                    <i class="fas" :class="(passwordFieldType=='text')?'fa-eye':'fa-eye-slash'"></i>
                                                                </span>
                                                            </div>
                                                             <div class="justify-content-start set-err-text"
                                                                v-if="showValidationError && form.password == ''">
                                                                <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password.')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="passwordValidation">
                                                                <img class="succ-wrong-img" v-if="!passwordValidation1" src="@/assets/images/request-right-icon.webp" alt="">
                                                                <img class="succ-wrong-img" v-if="passwordValidation1" src="@/assets/images/wrong-icon.webp" alt="">
                                                                <p :class="passwordValidation1 ? 'wrong-color':'success-color'">{{translatedLangData('must-be-between-8-20-characters','Must be between 8-20 characters!')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="passwordValidation">
                                                                <img class="succ-wrong-img" v-if="!passwordValidation2" src="@/assets/images/request-right-icon.webp" alt="">
                                                                <img class="succ-wrong-img" v-if="passwordValidation2" src="@/assets/images/wrong-icon.webp" alt="">
                                                                <p :class="passwordValidation2 ? 'wrong-color':'success-color'">{{translatedLangData('must-be-alphanumeric','Must be Alphanumeric!')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="passwordValidation">
                                                                <img class="succ-wrong-img" v-if="passwordValidation3" src="@/assets/images/request-right-icon.webp" alt="">
                                                                <img class="succ-wrong-img" v-if="!passwordValidation3" src="@/assets/images/wrong-icon.webp" alt="">
                                                                <p :class="!passwordValidation3 ? 'wrong-color':'success-color'">{{translatedLangData('must-contain-at-least-1-in-capital-case','Must Contain At Least 1 In Capital Case!')}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="password-input"> <span>{{translatedLangData('confirm-password','Confirm Password')}}* </span>
                                                            <div class="changepassword-box">
                                                                <input class="pass_log_id" :type="confPasswordFieldType" v-model="form.confirmPassword" :placeholder="translatedLangData('enter-conf-password','Enter Confirm password')"
                                                                    maxlength="20" minlength="8" @input="validateConfirmPassword" 
                                                                    @keyup="checkPasswordMatch" :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }">
                                                                <span @click="toggleShow" class="fa fa-fw field_icon toggle-password">
                                                                    <i class="fas" :class="(confPasswordFieldType=='text')?'fa-eye':'fa-eye-slash'"></i>
                                                                </span>
                                                            </div>
                                                            <div class="justify-content-start set-err-text">
                                                                <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-color">
                                                                    {{translatedLangData('please-enter-confirm-password','Please Enter Confirm Password.')}}</p>
                                                                <p v-if="confirmPasswordValidation && form.password != form.confirmPassword" class="wrong-color">
                                                                    {{translatedLangData('password-mismatch','Password Mismatch.')}}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <Captcha v-if="!checkIsLogin && getCaptchaData && isOtpApiCalled" :showValidationError="showErrorMobile" :form="form" />

                                                        <div class="password-input"> <span>{{translatedLangData('refer-code','Refer Code')}}</span>
                                                            <input type="text" v-model="this.form.referCode"
                                                            @input="validateReferCode" :disabled="isDisabled || hasReferCode" :class="{ 'disabled-input': isDisabled || hasReferCode }"
                                                            :placeholder="translatedLangData('enter-refer-code','Enter Refer Code')">
                                                        </div>
                                                        <!-- <div class="justify-content-start set-err-text pass-vali-div" v-if="showReferValidation">
                                                            <img class="succ-wrong-img" v-if="!referCodeValidation" src="@/assets/images/request-right-icon.webp" alt="">
                                                            <img class="succ-wrong-img" v-if="referCodeValidation" src="@/assets/images/wrong-icon.webp" alt="">
                                                            <p :class="referCodeValidation ? 'wrong-color':'success-color'">{{translatedLangData('refer-code-validation-msg','The Refer code must be alphanumeric and should be of 6 characters!')}}</p>
                                                        </div> -->
                                                    </div>
                                                    
                                                    <button type="button" @click.prevent="signUpCall()" class="login-btn" :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }">
                                                        <span>{{translatedLangData('sign-up','Sign Up')}}</span>
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="login-with">
                                        <div class="form-line"></div>
                                        <span>{{translatedLangData('or-register-with','or register with')}}</span>
                                        <div class="form-line"></div>
                                    </div>
                                    <div class="exch-social-icon-wrapper" v-if="siteSettings && siteSettings?.social_link">
                                        <ul class="exch-social-icon-sec">
                                            <li>
                                                <a v-if="siteSettings?.social_link?.whatsappno1" :href="'//wa.me/'+siteSettings?.social_link?.whatsappno1" target="_blank" class="whatsapp social-media-link">
                                                    <img  src="@/assets/images/whatsapp-icon.webp" alt="whatsapp">
                                                </a>
                                            </li>
                                            <li>
                                                <a v-if="siteSettings?.social_link?.facebook_link" :href="siteSettings?.social_link?.facebook_link" target="_blank">
                                                    <img  src="@/assets/images/facebook-login.webp">
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <img  src="@/assets/images/google-login.webp">
                                                </a>
                                            </li>
                                            <li>
                                                <a v-if="siteSettings?.social_link?.instagram_link" :href="siteSettings?.social_link?.instagram_link" target="_blank">
                                                    <img  src="@/assets/images/instagram-login.webp">
                                                </a>
                                            </li>
                                            <li>
                                                <a v-if="siteSettings?.social_link?.telegram_link" :href="siteSettings?.social_link?.telegram_link.includes('https://www.') || siteSettings?.social_link?.telegram_link.includes('https://')? siteSettings?.social_link?.telegram_link : 'https://www.'+siteSettings?.social_link?.telegram_link" target="_blank">
                                                    <img  src="@/assets/images/telegram-login.webp">
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="login-box1" 
                                        v-if="siteSettings && siteSettings.social_link && siteSettings?.social_link.whatsappno1">
                                        <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank"
                                            class="whatsup-login" style="cursor: pointer;">
                                            <img  src="@/assets/images/whatsup-icon1.webp" alt="whatsup-icon1">
                                            <span>{{translatedLangData('get-instant-id-on-whatsapp','Get Instant ID on Whatsapp')}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="marquee-container">
                    <div class="marquee-content">
                        <div class="bonus-box" v-if="bonusData">
                            <div :class="index % 2 == 0 ? 'bonus-card-box1' :'bonus-card-box'" v-for="(item,index) in bonusData" :key="index">
                                <img loading="lazy" src="@/assets/images/star.svg" alt="" class="star3">
                                <div :class="index % 2 == 0 ? 'bonus-inner-card1' : 'bonus-inner-card'">
                                <img loading="lazy" src="@/assets/images/bonus-gift-img-2.webp" alt="" class="bonus-img">
                                <span v-if="item?.bonusType == 'register'">{{item?.modeAmount}} {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('on-register','On Registeration')}}</span>
                                <span v-if="item?.bonusType == 'welcome'">₹ {{item?.welcomeBalance}} {{ translatedLangData('welcome-balance','Welcome Bonus')}}</span>
                                <span v-if="item?.bonusType == 'first refer&earn'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('first-refer-earn','First Refer & Earn')}}</span>
                                <span v-if="item?.bonusType == 'next 4 refer&earn'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('next-4-refer-earn','Next 4 Refer & Earn')}}</span>
                                <span v-if="item?.bonusType == 'first deposit'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('1st-deposit','1st Deposit')}}</span>
                                <span v-if="item?.bonusType == 'every deposit'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('eveery-deposit','Every Deposit')}}</span>
                                </div>
                                <img loading="lazy" src="@/assets/images/star.svg" alt="" class="star4">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="login-btn d-none" data-bs-toggle="modal" href="#loginModalToggle" ref="open_login_modal">{{translatedLangData('log-in','Log in')}}</button>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { mapGetters } from 'vuex';
import { Modal } from "bootstrap";

export default {
    name: "SignUp",
    inject:['translatedLangData'],
    props:['bonusData'],
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings.cncode ? this.siteSettings.cncode : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
            showValidationError: false,
            showUserNameError: false,
            passwordValidation: false,
            showReferValidation: false,
            referCodeValidation: false,
            passwordValidation1: false,
            passwordValidation2: false,
            passwordValidation3: false,
            userIdValidation: false,
            userIdValidation1: false,
            userIdValidation2: false,
            showErrorMobile: false,
            loadingSignUp: false,
            loadingOtp: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 5,
            seconds: 0,
            passwordFieldType:'password',
            confPasswordFieldType:'password',
            showErrorNumberLength: false,
            confirmPasswordValidation: false,
            passwordFlag: true,
            referFlag: true,
            userIdFlag: true,
            phnValid: false,
            initialPhnIcon: false,
            isChecked:false,
            clickCountry: false,
            selectedCountryflag: 'https://flagcdn.com/in.svg',
            isDisabled: true,
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings'
        }),
        // checkIsLogin() {
        //     return this.$store.getters.isAuthenticated;
        // },
        getCaptchaData() {
            this.captchaData = this.$store.getters.captchaData
            return this.captchaData;
        },
        hasReferCode() {
            return (window.location.href).includes('refer_code');
        }
       
    },
    mounted(){
        if(this.$route.name == 'sign-up' && !this.checkIsLogin()){
            let loginModal = new Modal('#registerModalToggle');
            loginModal.show();
        }
        else if(this.$route.name == 'sign-up' && this.checkIsLogin()){
            this.$router.push('/')
        }
        document.addEventListener('click', this.closeDropdownOnClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
    },
    methods: {
        closeDropdownOnClickOutside(event) {
            if (this.$refs.dropdownWrapper) {
                if (!this.$refs.dropdownWrapper.contains(event.target)) {
                    this.clickCountry = false;
                }
            }
        },
        selectCountry(item){
            this.form.code = item?.phonecode;
            this.selectedCountryflag = item?.flag;
            this.clickCountry = false;
        },
        toggleShow(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text';

            } else {
                this.confPasswordFieldType = (this.confPasswordFieldType === 'text') ? 'password' : 'text';
            }
        },
        refreshData() {
            this.form = {
                code: "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.referCodeValidation = false,
                this.passwordValidation1 = false,
                this.passwordValidation2 = false,
                this.passwordValidation3 = false,
                this.userIdValidation1 = false,
                this.userIdValidation2 = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0,
                this.showErrorNumberLength = false,
                this.isChecked = false
        },
        sendOtpCall() {
            let getMobileNo = this.form.mobileNo;

            if (!this.loadingOtp) {
                if (!this.form.mobileNo) {
                    this.showErrorMobile = true;
                } else if(getMobileNo.toString().length != 10) {
                    this.showErrorNumberLength = true;
                } else {
                    this.sendOtpServiceCall();
                }
            }
        },
        checkMobileNoLength(event) {
            this.initialPhnIcon = true;
            this.form.mobileNo = event.target.value.replace(/\D/g, ''); // Extract only numeric characters from the input value
            this.phnValid = this.form.mobileNo.length == 10;
        },

        handleInputUserName(event){
            if(this.userIdFlag){
                this.userIdFlag = false;
                this.userIdValidation = true;
            }
            this.form.userName = event.target.value.replace(/[^a-zA-Z0-9]/g,'');
            const minLength = 6;
            const maxLength = 25;

            if (this.form.userName.length > maxLength) {
                this.form.userName = this.form.userName.slice(0, maxLength);
            }
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(this.form.userName);
            const containsSpaces = /\s/.test(this.form.userName); 
            const containsAlphabets = /^(?=.*[a-zA-Z]).*$/.test(this.form.userName);
            const containsNumbers = /^(?=.*\d).*$/ .test(this.form.userName);
            this.userIdValidation1 = this.form.userName.length < minLength || this.form.userName.length > maxLength;
            this.userIdValidation2 =  !containsAlphabets || !containsNumbers;
            if (containsSpaces) {
                this.form.userName = this.form.userName.replace(/\s/g, '');
            }
        },

        async sendOtpServiceCall() {
            this.loadingOtp = true;
            api.post(apiName.SEND_OTP, {
                mobile_number: this.form.mobileNo.toString(),
                cncode: this.form.code
            }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.showErrorMobile = false;
                        this.showErrorNumberLength = false;
                        this.isDisabled = false;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },

        validatePassword() {
            if(this.passwordFlag){
                this.passwordFlag = false;
                this.passwordValidation = true;
            }
            const password = this.form.password;
            const minLength = 8;
            const maxLength = 20;
            this.form.password = password.replace(/[^a-zA-Z0-9]/g, '');
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(password);
            const containsAlphabets = /^(?=.*[a-zA-Z]).*$/.test(password);
            const containsNumbers = /^(?=.*\d).*$/ .test(password);
            const containsSpaces = /\s/.test(password); // Check if password contains spaces
            this.passwordValidation1 = password.length < minLength || password.length > maxLength;
            this.passwordValidation2 = !containsAlphabets || !containsNumbers;
            this.passwordValidation3 = /[A-Z]/ .test(this.form.password);
            if (containsSpaces) {
                this.form.password = password.replace(/\s/g, '');
            }
        },

        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },

        signUpCall() {
            if (!this.isOtpApiCalled) {
                return
            }
            if (!this.loadingSignUp) {
                var regex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
                let o = this.getOtp();
                // this.passwordValidation = false;
                if (!this.form.mobileNo) {
                    this.showErrorMobile = true;
                } else if (o.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                    this.showValidationError = true;
                }else if(!regex.test(this.form.password)){
                    // this.passwordValidation = true;
                } 
                else if (this.form.password != this.form.confirmPassword) {
                    // this.passwordValidation = true;
                }
                else {
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "cncode": this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$refs.open_login_modal.click();
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            if(message != ''){
                this.$emit('error-modal', message);
            }
        },
        showSuccessModalFunc(message) {
            if(message != ''){
                this.$emit('success-modal', message);
            }
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },

        validateReferCode() {
            if(this.referFlag){
                this.referFlag = false;
                this.showReferValidation = true;
            }
            const alphanumericRegex = /^[a-zA-Z0-9]*$/;
            const containsAlphabets = /^(?=.*[a-zA-Z]).*$/.test(this.form.referCode);
            const containsNumbers = /^(?=.*\d).*$/ .test(this.form.referCode);
            // if (this.form.referCode.length > 6) {
            //     this.form.referCode = this.form.referCode.slice(0, 6);
            // }
            if (!this.form.referCode.match(alphanumericRegex)) {
                this.form.referCode = this.form.referCode.replace(/[^a-zA-Z0-9]/g, '');
            }
            this.referCodeValidation = ( !containsAlphabets || !containsNumbers ) || this.form.referCode.length != 6;

        },

        validateConfirmPassword(){
            this.form.confirmPassword = this.form.confirmPassword.replace(/[^a-zA-Z0-9]/g, '');
        },
        checkPasswordMatch(event) {
            let confirmPasswordVal = this.form.confirmPassword;
            if(confirmPasswordVal.length > 0) {
                confirmPasswordVal = event.target.value.replace(/\s/g, ''); // Remove any spaces from the input
                if (confirmPasswordVal != this.form.password) {
                    this.confirmPasswordValidation = true;
                } 
            } else {
                this.confirmPasswordValidation = false;
            }
        },

    }
}
</script>

<style>
.disabled-input {
  /* background-color: #bebebe !important; Set the color for disabled input fields */
  opacity: .5 !important;
}
.bonus-card-box span, .bonus-card-box1 span{
    width: 7.5rem;
    color: #fff;
    font-size: .9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: .01875rem;
}
</style>