<template>
    <div class="headernavBarmenu-wrapper">
        <div class="container">
            <div class="headernavBarmenu-scroll">
                <ul class="headernavBarmenu-list">

                    <!-- <li>
                        <router-link :to="{ name: 'Home' }">Inplay<div class="active-line"></div></router-link>
                    </li> -->

                    <li :class="{ 'headernavBaractive': activeType == 1 }" @click="setActiveType(1)">
                        <router-link :to="{ name: 'sports-book' }">{{translatedLangData('kabaddi','Kabaddi')}}<div class="active-line"></div></router-link>
                    </li>
                    <li :class="{ 'headernavBaractive': activeType == 13 }" @click="setActiveType(13)">
                        <router-link :to="{ name: 'matka'}">{{translatedLangData('matka','Matka')}}<div class="active-line"></div></router-link>
                    </li>
                    
                    <li :class="{ 'headernavBaractive': activeType == 2 }" @click="setActiveType(2)">
                        <a @click="clickOnGamestButton(aviatorItem)">{{translatedLangData('aviator','Aviator')}}<div class="active-line"></div></a>
                    </li>
                    
                    <li :class="{ 'headernavBaractive': activeType == 3 }">
                        <a @click="clickOnGamestButton(teenPattiItem,3)">{{translatedLangData('teenpatti','Teenpatti')}}<div class="active-line"></div></a>
                    </li>

                    <li :class="{ 'headernavBaractive': activeType == 4 }" @click="setActiveType(4)" >
                        <router-link :to="{ name: 'games', params: { type: 'LIVE_CASINO' } }">
                            {{translatedLangData('live-casino','Live Casino')}} <div class="active-line"></div>
                        </router-link>
                    </li>

                    <li :class="{ 'headernavBaractive': activeType ==5 }" @click="setActiveType(5)">
                        <router-link :to="'/sports/' + 'cricket' + '/' + '4'">{{translatedLangData('sports','Sports')}}<div class="active-line"></div></router-link>
                    </li>
                    
                    <li :class="{ 'headernavBaractive': activeType == 6 }" @click="setActiveType(6)">
                        <router-link :to="{ name: 'sports-book' }">{{translatedLangData('sportsbook','Sportsbook')}}<div class="active-line"></div></router-link>
                    </li>
                    
                    <li :class="{ 'headernavBaractive': activeType == 7 }" @click="setActiveType(7)">
                        <router-link :to="{ name: 'sports-book'}">{{translatedLangData('virtual-sports','Virtual Sports')}}<div class="active-line"></div></router-link>
                    </li>

                    <li :class="{ 'headernavBaractive': activeType == 8 }" @click="setActiveType(8)">
                        <router-link :to="{ name: 'games', params: { type: 'CASINO' }}">{{translatedLangData('casino','Casino')}}<div class="active-line"></div></router-link>
                    </li>

                    <li :class="{ 'headernavBaractive': activeType == 9 }" @click="setActiveType(9)">
                        <router-link :to="{ name: 'shared-games', params: { type: 'poker' }}">{{translatedLangData('poker','Poker')}}<div class="active-line"></div></router-link>
                    </li>

                    <li :class="{ 'headernavBaractive': activeType == 10 }" @click="setActiveType(10)">
                        <router-link :to="{ name: 'shared-games', params: { type: 'games' }}">{{translatedLangData('games','Games')}}<div class="active-line"></div></router-link>
                    </li>
                    <li :class="{ 'headernavBaractive': activeType == 11 }" >
                        <a @click="clickOnGamestButton(roullateItem,11)">{{translatedLangData('non-stop-roulette','Non-stop Roulette')}}<div class="active-line"></div></a>
                    </li>

                    <!-- <li>
                        <router-link :to="{ name: 'shared-games', params: { type: 'dream-wheel' }}">Dreamwheel<div class="active-line"></div></router-link>
                    </li> -->
                    <li :class="{ 'headernavBaractive': activeType == 12 }">
                        <a @click="clickOnGamestButton(dreamwheelItem,12)">{{translatedLangData('dreamwheel','Dreamwheel')}}<div class="active-line"></div></a>
                    </li>

                     <!-- <li>
                        <router-link :to="{ name: 'sports-book'}">Horse Racing<div class="active-line"></div></router-link>
                    </li>
                    
                    <li>
                        <router-link :to="{ name: 'sports-book'}">Grey Hound Racing<div class="active-line"></div></router-link>
                    </li> -->
                    
                   <!-- <li v-for="(sport, n_index) in non_custom_sports" :key="n_index" v-show="checkInEventMange(sport.slug)"
                        :class="{ headernavBaractive : $route.name === 'sports' && $route.params.type === sport.name }">
                        <router-link :to="'/sports/' + sport.slug + '/' + sport.id">
                            {{ getCapitalizedText(sport.name) }}
                            <div class="active-line"></div>
                        </router-link>
                    </li>
                    
                    <li>
                        <router-link :to="{ name: 'games', params: { type: 'WORLD_CASINO' } }">
                            World Casino <div class="active-line"></div>
                        </router-link>
                    </li>
                    
                    <li>
                        <router-link :to="{ name: 'matka' }">
                            Matka <div class="active-line"></div>
                        </router-link>
                    </li>
                    
                    <li v-for="(c_sport, c_index) in custom_sports" :key="c_index" v-show="checkInEventMange(c_sport.slug)"
                        :class="{ headernavBaractive : $route.name === 'sports' && $route.params.type === lowerCaseText(c_sport.name) }">
                        <router-link :to="'/sports/' + c_sport.slug + '/' + c_sport.id">
                            {{ c_sport.name }} <div class="active-line"></div>
                        </router-link>
                    </li> -->

                </ul>
                <button class="menu-btn-nav" data-bs-toggle="modal" data-bs-target=".footermenumodal">
                    <span class="fa-solid fa-chevron-down">
                    </span>
                </button>
            </div>
        </div>
    </div>

    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#loginModalToggle"></div>
    <div ref="games_pop_1_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_1"></div>
    <div ref="games_pop_2_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_2"></div>
    <!-- <div ref="iframe_pop_modal_games_sports" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal_games_sports"></div>

    <div class="modal fade" id="iframe_pop_modal_games_sports" aria-hidden="true" aria-labelledby="loginModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered" @click="closeModalIframe()">
            <IFrameModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" v-if="openIframeModal" />
        </div>
    </div> -->
</template>

<script>
import IFrameModal from '@/modules/games/components/IFrameModal.vue';
import * as apiName from '../../services/urls';
import clickApi from '../../services/click-api';
import { setGameItem } from "@/shared/constants/shared-data";

export default {
    name: "SportSelection",
    components: {
        navigator: true,
        IFrameModal
    },
    inject:['translatedLangData'],
    props: ['gameItem', 'success-fav'],
    emits: ['open-games-pop-up', 'open-iframe-modal'],
    data: () => ({
        non_custom_sports: [],
        custom_sports: [],
        clickApiUrl: process.env.VUE_APP_CLICK_API + 'v1/aws-s3/download/',
        openIframeModal: false,
        aviatorItem: null,
        teenPattiItem: null,
        roullateItem: null,
        dreamwheelItem: null,
        activeType:0
    }),
    created() {
        this.get_sportsList();
        let sectionsList =  this.$store?.getters?.gamesData?.sections;
        this.aviatorItem = sectionsList?.filter(item => item.slug == 'aviator')[0]?.games?.[0];
        this.teenPattiItem = sectionsList?.filter(item => item.slug == 'teen-patti-home')[0]?.games?.[0];
        this.roullateItem = sectionsList?.filter(item => item.slug == 'roullate')[0]?.games?.[0];
        this.dreamwheelItem = sectionsList?.filter(item => item.slug == 'dream-wheel')[0]?.games?.[0];
    },
    methods: {
        setActiveType(type) {
            this.activeType = type;
        },
        closeModalIframe() {
            this.openIframeModal = false
        },
        checkInEventMange(type) {
            return this.$store.getters.eventManage ? this.$store.getters.eventManage?.filter(s => s.type == type).length != 0 ? false : true : true;
        },
        get_sportsList() {
            this.loading = true;
            clickApi.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if (all_sports[i].is_custom == 0) {
                                this.non_custom_sports.push(all_sports[i])
                            } else {
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {};
                        sport_list['custom'] = this.custom_sports;
                        sport_list['non_custom'] = this.non_custom_sports;
                        localStorage.setItem('sports_List', JSON.stringify(sport_list));
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error);
                }
            });
        },
        clickOnGamestButton(item,type) {
            this.activeType = type;
            if (this.checkIsLogin()) {
                this.openGamesPopup(item);
            } else {
                this.$refs.open_login_modal.click();
            }
        },
        userData() {
            return this.$store.getters.stateUser;
        },
        openGamesPopup(gameItem) {
            setGameItem(gameItem);
            switch (this.userData().currency) {
                case 1:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    }
                    else if (gameItem.only_hkd == 0) {
                        //this.openIframeModal = true;
                        //this.$refs.iframe_pop_modal_games_sports.click();
                        this.$emit('open-iframe-modal');
                    }
                    break;
                case 2:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    } else if (gameItem.only_hkd == 0) {
                        this.openIframeModal = true;
                        this.$refs.games_pop_2_modal.click();
                    }
                    break;
                case 3 || 4:
                    //this.openIframeModal = true;
                    //this.$refs.iframe_pop_modal_games_sports.click();
                    this.$emit('open-iframe-modal');
                    break;
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        getCapitalizedText(text) {
            return text[0].toUpperCase() + text.substring(1)
        },
        lowerCaseText(text) {
            return text.toLowerCase().replace(/\s+/g, '-');
        }
    }
};
</script>