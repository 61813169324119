<template>
    <div class="trending-all-gms-wrapper">
        <div class="container">
            <div class="trending-all-gms-sec">
                <router-link :to="'/sports/' + sport.slug + '/' + sport.id" :id="'nav-' + sport.slug +'-tab'" 
                    v-for="sport in sportList" :key="sport.id">
                    <div class="trending-all-sport-opt" :class="getSportClass(sport)">
                        <img v-if="sport.id == 4" src="@/assets/images/menu-cricket.webp" alt="">
                        <img v-if="sport.id == 1" src="@/assets/images/menu-soccer.webp" alt="">
                        <img v-if="sport.id == 2" src="@/assets/images/menu-tennis.svg" alt="">
                        <span class="title-all-sport">{{ sport.name.toLowerCase() == 'soccer' ? 'Football' : getCapitalizedText(sport.name) }}</span>
                    </div>
                </router-link>
                <router-link :to="'/sports/' + sport.slug + '/' + sport.id" :id="'nav-' + sport.slug +'-tab'" 
                    v-for="sport in customSportList" :key="sport.id">
                    <div class="trending-all-sport-opt" :class="getSportClass(sport)">
                        <img v-if="sport.id == 648" src="@/assets/images/menu-kabaddi.webp" alt="">
                        <img class="exch-custom-sports-img" v-if="sport.id == 4340" src="@/assets/images/ipl.png" alt="">
                        <img class="exch-custom-sports-img" v-if="sport.id == 4341" src="@/assets/images/loksabha.png" alt="">
                        <span class="title-all-sport">{{ getCapitalizedText(sport.name) }}</span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SportSelection",
    data: () => ({
        getSportsName: null,
        sportList : null,
        customSportList : null,
        // customSportList : JSON.parse(localStorage.getItem('sports_List'))['custom'],
    }),
    mounted() {
        this.getSportsName = this.$route.params.type;

        const storedSportsList = JSON.parse(localStorage.getItem('sports_List'));

        if (storedSportsList && 'non_custom' in storedSportsList) {
            this.sportList = storedSportsList['non_custom'];
        } else {
            console.error("'non_custom' not found in localStorage");
        }

        if (storedSportsList && 'custom' in storedSportsList) {
            this.customSportList = storedSportsList['custom'];
        } else {
            console.error("'custom' not found in localStorage");
        }
    },
    methods: {
        checkInEventMange(type) {
            return this.$store.getters.eventManage ? this.$store.getters.eventManage?.filter(s => s.type == type).length != 0 ? false : true : true;
        },
        getCapitalizedText(text) {
            return text[0].toUpperCase() + text.substring(1)
        },
        getSportClass(sport) {
            if(this.getSportsName == sport.slug) {
                if(sport.id == 1 || sport.id == 2 || sport.id == 4) {
                    return `${sport.slug}-sport active`;
                } else {
                    return `cricket-sport active`;
                }
            }
        }
    }
};
</script>