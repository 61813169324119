const state = {
    siteSettings: null,
    captchaData: null,
    globalLoader:false,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    gamesData: null,
    openBetsData: 0,
    leagueWiseMatches: null,
    languagesData:null,
};

const getters = {
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.languagesData,
    captchaData: state => state?.captchaData,
    globalLoader:state => state?.globalLoader,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    clickBetActive: state => state?.clickBetValue?.find(item => item.isSelected) ?? false,
    gamesData: state => state?.gamesData,
    openBetsData: state => state?.openBetsData,
    leagueWiseMatches: state => state?.leagueWiseMatches,
};

const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setOpenBetsData({ commit }, openBetsData) {
        commit('setOpenBetsData', openBetsData);
    },
    setLeagueWiseMatches({ commit }, leagueWiseMatches) {
        commit('setLeagueWiseMatches', leagueWiseMatches);
    },
};

const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setOpenBetsData(state, openBetsData) {
        state.openBetsData = openBetsData;;
    },
    setLeagueWiseMatches(state, leagueWiseMatches) {
        state.leagueWiseMatches = leagueWiseMatches;;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};