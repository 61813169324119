<template>
<div class="home-page-loader" v-if="getGlobaLoader()">
    <img alt="logo" class="loader-img" id="logoImage" :src="siteSettings.aws_url + siteSettings.storage_path?.domain_image + siteSettings.logo">
    <img alt="spinner" class="spinner" id="loader" src="@/assets/images/loader.gif">
</div>
<!-- <div class="notify" v-if="showMarquee && siteSettings && siteSettings.announcement && siteSettings.announcement.msg">
    <marquee :style="{ color: siteSettings?.announcement?.color_code }" behavior="" direction="">
        <div class="notification-msg">
            <span v-html="siteSettings.announcement.msg"></span>
        </div>
    </marquee>
    <svg @click="showMarquee=!showMarquee" xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 14 15" fill="none" style="border: 1px solid #9CA3AF; background: #9CA3AF; border-radius: 50%;">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.91728 10.7587C2.68949 10.9866 2.68952 11.3559 2.91735 11.5837C3.14517 11.8115 3.51452 11.8115 3.7423 11.5836L7.00036 8.32499L10.2587 11.5833C10.4865 11.8111 10.8558 11.8111 11.0836 11.5833C11.3114 11.3555 11.3114 10.9862 11.0836 10.7584L7.82525 7.49998L11.0834 4.24126C11.3111 4.01343 11.3111 3.64409 11.0833 3.41631C10.8555 3.18851 10.4861 3.18855 10.2583 3.41638L7.00024 6.67503L3.74191 3.41666C3.5141 3.18885 3.14475 3.18885 2.91695 3.41666C2.68914 3.64446 2.68914 4.01381 2.91695 4.24162L6.17541 7.50004L2.91728 10.7587Z" fill="#111827">
        </path>
    </svg>
</div> -->
    <div class="header-wapper-container">
        <div class="container">
            <div class="header-navbar">
                <div v-if="siteSettings">
                    <a @click="goToHome()" class="logo-a">
                        <img class="logo-imgtag" :src="siteSettings.aws_url + siteSettings.storage_path?.domain_image + siteSettings.logo" alt="logo" >
                    </a>
                </div>
                <div  v-if="!checkIsLogin()" class="navbar-button-sec">
                    <div class="login-btn-sec">
                        <button class="login-btn" data-bs-toggle="modal" href="#loginModalToggle">{{translatedLangData('log-in','Log in')}}</button>
                    </div>
                    <div class="register-btn-sec" v-if="siteSettings?.business_type != 1">
                        <button class="register-btn" data-bs-toggle="modal" href="#registerModalToggle">{{translatedLangData('register','Register')}}</button>
                    </div>
                </div>
                <div v-else class="exch-header-content">
                    <template v-if="cricketFightRoute()">
                        <div class="exch-header-content">
                            <div class="exch-bal-exp-btn-sec"> 
                                <!-- <a v-if="$route.name == 'MatchDetails' || $route.name == 'PlaceBet'" class="thm-but thm-bor-but share-btn " data-bs-toggle="modal" data-bs-target="#shareModal">
                                    <img src="@/assets/images/share-icon.webp" alt="">
                                </a> -->
                                <a v-if="siteSettings?.business_type != 1" href="#javascript:void(0)" class="cricket-fight-deposit-btn"><span>{{translatedLangData('deposit','Deposit')}}</span></a> 
                                <a href="#javascript:void(0)" class="cricket-fight-fp-btn"><span>FP :</span><span>{{ stateUser.balance }}</span></a> 
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <a @click="getWalletGateway()" >
                            <div class="balance-box">
                                <span class="balance-amt" v-if="showBalanceProperty == 'false'"> {{ stateUser.balance }}</span>
                                <span class="balance-amt" v-else> XXX.XX</span>
                            </div>
                        </a>
                        <a @click="getWalletGateway()" v-if="siteSettings?.business_type != 1">
                            <div class="deposit-button">
                                <img loading="lazy" src="@/assets/images/wallet.webp" alt="wallet">
                                <span class="btn-text">{{translatedLangData('deposit','Deposit')}}</span>
                            </div>
                        </a>
                        <router-link to="/account">
                            <div class="person-logo">
                                <img src="@/assets/images/person-logo.webp" alt="person">
                            </div>
                        </router-link>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";

export default {
    name: "Header",
    inject:['translatedLangData'],
    data() {
        return {
            showBalanceProperty: localStorage.getItem('showBalanceProperty') || 'false',
            showMarquee: true
        }
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings', stateUser : 'stateUser'
        })
    },
    methods: {
        getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        goToHome() {
            this.$router.push({ name: 'Home' });
        },
        getWalletGateway() {
            if(this.siteSettings?.business_type != 1){
                this.$router.push({name: 'wallet-gateway'});
            }
            // getWalletGetwayServiceCall();
        },
        cricketFightRoute() {
			let routeName = this.$route.name
			let restrictedRoutes = [
				'cricket-fight',
				'MatchDetails',
				'PlaceBet',
				'my-contests',
				'MyContestBetDetails'
			]
			return restrictedRoutes.includes(routeName);
		},
    }
}
</script>
<style scoped>
.logo-a{
    display: flex;
    align-items: center;
    height: 46px;
}
.logo-imgtag{
    display: flex;
    height: 100%;
    object-fit: contain;
    padding: 2px 0;
    max-width: 100px !important;
}
.notify{
    display: flex;
    width: 100%;
    padding: 4px 12px;
}
.notification-msg{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.notification-msg span{
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}
</style>